<template>
  <div class="notPage_box">
    <img :src="require('@/assets/imgs/nonetwork.png')" alt="">
    <div class="backBtn" @click="goback">重新登录</div>
  </div>
</template>

<script>
export default {
  methods: {
    goback () {
      this.$mgr.signinRedirect()
    }
  }
}
</script>

<style lang="scss" scoped>
.notPage_box {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.backBtn {
  border: 1px solid #4bcebb;
  border-radius: 15px;
  font-size: 14px;
  padding: 5px 15px;
  background: #4bcebb;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
</style>